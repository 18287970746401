function loadWidget() {
    var pricingTableComponentOptions = {
        id: 'zf-widget-root-id',
        product_id: '2-780b4ddda25868a4b97aeac1534bbe4a838080d1ca995d6a2b698517d44177f5728c15acb385275958bcad6fd428a6fd38260d0ce92b6876318fde6c950d2d25',
        template: 'elegant',
        most_popular_plan: '',
        is_group_by_frequency: true,
        group_options: [
            {
                frequency: 'Monthly',
                frequency_recurrence_value: '1_months',
                most_popular_plan: '',
                plans: [
                    {
                        plan_code: 'm001'
                    },
                    {
                        plan_code: 'trial'
                    },

                ]
            },
            {
                frequency: '12 Month(s)',
                frequency_recurrence_value: '12_months',
                most_popular_plan: '',
                plans: [
                    {
                        plan_code: 'MIYY'
                    },

                ]
            },
            {
                frequency: 'Quarterly',
                frequency_recurrence_value: '3_months',
                most_popular_plan: '',
                plans: [
                    {
                        plan_code: 'q001'
                    },

                ]
            },
            {
                frequency: 'Bi-Yearly',
                frequency_recurrence_value: '6_months',
                most_popular_plan: '',
                plans: [
                    {
                        plan_code: 'half001'
                    },
                    {
                        plan_code: 'MIHY'
                    },

                ]
            },

        ],
        plans: [

        ],
        theme: { color: '#7952b3', theme_color_light: '' },
        button_text: 'Subscribe',
        product_url: 'https://subscriptions.zoho.com',
        price_caption: '',
        language_code: 'en',
        open_inSameTab: false
    };
    ZFWidget.init('zf-pricing-table', pricingTableComponentOptions);
};
