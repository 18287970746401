
function openNav() {
  sideWidt = document.getElementById("mySidenav").style.width;
  if (sideWidt == "12%") {
    document.getElementById("mySidenav").style.width = "4%";
    document.getElementById("main").style.paddingLeft = "4%";
    $(".spn").css("display", "none");
  }
  else {
    document.getElementById("mySidenav").style.width = "12%";
    document.getElementById("main").style.paddingLeft = "12%";
    $(".spn").css("display", "inline");
  }

}

function closeNav() {

  /* Breadcrumb menu */

  /* $(".a > li a").not("ul li ul a").click(function () {
    var value = $(this).text();
    $('.breadcrumb li:nth-child(1)').text(value + ' > ');
    $('.breadcrumb li:nth-child(2)').css("display", "none");
    $('.breadcrumb li:nth-child(3)').css("display", "none");
  });

  $(".b > li a").click(function () {
    var value = $(this).text();
    $('.breadcrumb li:nth-child(2)').css("display", "block");
    $('.breadcrumb li:nth-child(2)').text(value + ' > ');
    $('.breadcrumb li:nth-child(3)').text(' ');
  });

  $(".c > li a").click(function () {
    var value = $(this).text();
    $('.breadcrumb li:nth-child(2)').css("display", "none");
    $('.breadcrumb li:nth-child(3)').css("display", "block")
    $('.breadcrumb li:nth-child(3)').text('Master > ' + value);
  });
 */
  /* Breadcrumb menu ends */

  var screenSize = screen.width;
  if (screenSize <= 1024) {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.paddingLeft = "0px";
  }

}

function goBack() {
  window.history.back();
}

function adjustHeight(el) {
  el.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight) + "px" : "30px";
}





